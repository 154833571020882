import apiClient from './basicapi'

/* result item:
    {
      "id": 3,
      "userName": "hansfranz",
      "userEmail": "vlankabel@gmail.com",
      "timestamp": "2020-08-18T11:03:02",
      "title": "niemals",
      "text": "ich will nicht, weil nein",
      "topics": [
        "mobilfunktechnik"
      ],
      "numberOfLikes": 0,
      "nubmerOfExpertAnswers": 0,
      "numberOfUserAnswers": 0,
      "hasNotApprovedAnswersOrComments": false,
      "isPublished": false,
      "isDeleted": false,
      "editorApproved": false,
      "editorRejected": false,
      "rejectReason": null,
      "markReason": null
    }
 */

function postAdminFilter(
  offset,
  limit,
  onlyNewAnswersOrComments,
  onlyMarkedAnswersOrComments,
  magazineArticles = false,
  onlyWithAnswerOrCommentDrafts = false
) {
  return apiClient
    .post('ForumsAdmin/filter', {
      offset: offset,
      limit: limit,
      onlyNewAnswersOrComments: onlyNewAnswersOrComments,
      onlyMarkedAnswersOrComments: onlyMarkedAnswersOrComments,
      magazineArticles,
      onlyWithAnswerOrCommentDrafts: onlyWithAnswerOrCommentDrafts,
    })
    .then((res) => ({
      totalNumberOfResults: res.data.totalNumberOfResults,
      hasMoreResults: res.data.hasMoreResults,
      items: res.data.result,
    }))
    .catch((err) => ({ apiError: err }))
}

function postSearchByEmail(email) {
  return apiClient
    .post('ForumsAdmin/search-by-email', {
      email: email,
    })
    .then((res) => ({
      items: res.data,
    }))
    .catch((err) => ({ apiError: err }))
}

function postDeleteByEmail(email) {
  return apiClient
    .post('ForumsAdmin/delete-by-email', {
      email: email,
    })
    .then((res) => res.data)
    .catch((err) => ({ apiError: err }))
}

function approvePost(id, type = 'article') {
  return apiClient
    .put(`ForumsAdmin/${type}/${id}/approve`)
    .then((res) => res.data)
    .catch((err) => ({ apiError: err }))
}

function declinePost(id, reason, bann, bannreason, type = 'article') {
  return apiClient
    .put(`ForumsAdmin/${type}/${id}/reject`, {
      reason: reason,
      lockAuthor: bann,
      lockAuthorReason: bannreason,
    })
    .then((res) => res.data)
    .catch((err) => ({ apiError: err }))
}

/**
 * @typedef {String} TypeDef
 * @value article
 * @value answer
 * @value comment
 */
/**
 *
 * @param {Number} id
 * @param {String} markReason
 * @param {TypeDef} type
 */
function markPost(id, markReason, type = 'article') {
  return apiClient
    .put(`ForumsAdmin/${type}/${id}/mark`, {
      reason: markReason,
    })
    .then((res) => res.data)
    .catch((err) => ({ apiError: err }))
}

function removeMarkPost(id, type) {
  return apiClient
    .put(`ForumsAdmin/${type}/${id}/mark`, {
      reason: null,
    })
    .then((res) => res.data)
    .catch((err) => ({ apiError: err }))
}

function writeAnswer(id, title, text, isDraft) {
  return apiClient
    .post(`ForumsAdmin/editorial-answer`, {
      articleId: id,
      title: title,
      text: text,
      isDraft: isDraft,
    })
    .then((res) => res.data)
    .catch((err) => ({ apiError: err }))
}

function writeComment(id, text, isDraft) {
  return apiClient
    .post(`ForumsAdmin/editorial-comment`, {
      answerId: id,
      text: text,
      isDraft: isDraft,
    })
    .then((res) => res.data)
    .catch((err) => ({ apiError: err }))
}

function editAnswer(id, title, text, isDraft, editReason) {
  return apiClient
    .put(`ForumsAdmin/editorial-answer/${id}`, {
      title: title,
      text: text,
      isDraft: isDraft,
      editReason: editReason,
    })
    .then((res) => res.data)
    .catch((err) => ({ apiError: err }))
}

function editComment(id, text, isDraft, editReason) {
  return apiClient
    .put(`ForumsAdmin/editorial-comment/${id}`, {
      text: text,
      isDraft: isDraft,
      editReason: editReason,
    })
    .then((res) => res.data)
    .catch((err) => ({ apiError: err }))
}

function deleteAnswer(id) {
  return apiClient
    .delete(`ForumsAdmin/editorial-answer/${id}`)
    .then((res) => res.data)
    .catch((err) => ({ apiError: err }))
}

function deleteComment(id) {
  return apiClient
    .delete(`ForumsAdmin/editorial-comment/${id}`)
    .then((res) => res.data)
    .catch((err) => ({ apiError: err }))
}

/*
{
    "title": "Beitragasdf 1",
    "topics": [
        "Thema I",
        "Thema II"
    ],
    "answers": [],
    "id": 1,
    "userName": "test1",
    "userEmail": "test@test.de",
    "userColor": 3,
    "timestamp": "2020-08-21T18:43:13",
    "text": "Inhalt des dasdf adf  Beitrags",
    "numberOfLikes": 3,
    "isPublished": true,
    "isDeleted": false,
    "editorApproved": false,
    "editorRejected": false,
    "rejectReason": null,
    "markReason": "In Bearbeitung",
    "autoPublicationEnabled": true
}
*/
function getPost(id) {
  return apiClient
    .get(`ForumsAdmin/articles/${id}`)
    .then((res) => res.data)
    .catch((err) => ({ apiError: err }))
}

function getSearchByEmailPost(id) {
  return apiClient
    .get(`ForumsAdmin/search-by-email/articles/${id}`)
    .then((res) => res.data)
    .catch((err) => ({ apiError: err }))
}

export {
  postAdminFilter,
  postSearchByEmail,
  postDeleteByEmail,
  approvePost,
  declinePost,
  markPost,
  getPost,
  getSearchByEmailPost,
  removeMarkPost,
  writeAnswer,
  writeComment,
  editAnswer,
  editComment,
  deleteAnswer,
  deleteComment,
}
