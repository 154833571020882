import apiClient from './basicapi'

function bannUser(name, email, reason) {
  return apiClient
    .post('ForumsAdmin/locked-users', {
      name: name,
      eMail: email,
      reason: reason,
    })
    .then((res) => res.data)
}

function getBannedUsers() {
  return apiClient.get('ForumsAdmin/locked-users').then((res) => res.data)
}

function removeUserFromBannList(id) {
  return apiClient
    .delete(`ForumsAdmin/locked-users/${id}`)
    .then((res) => res.data)
}

export { bannUser, removeUserFromBannList, getBannedUsers }
