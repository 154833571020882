import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import useDialog from '@hooks/useDialog'
import { IconButton, Menu, MenuItem, Divider } from '@material-ui/core'
import { MoreVertRounded } from '@material-ui/icons'

import { markPost, removeMarkPost } from '@services/postservice'
import { bannUser } from '@services/userservice'
import useSnackbar from '@hooks/useSnackbar'

function ContextMenu({
  mailto,
  id,
  type,
  userName,
  userEmail,
  mark,
  onMark,
  onRemoveMark,
  onBann,
  onCopy,
  onDelete,
  onDecline,
  isExpert,
}) {
  const intl = useIntl()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const dial = useDialog()
  const { toggleSnackbar } = useSnackbar()

  function handleClick(event) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  function setMark({ status }) {
    markPost(id, status, type).then((data) => {
      if (!data.apiError) {
        if (onMark) onMark()
      } else {
        toggleSnackbar(
          `Ein Fehler im System ist aufgetreten: ${data.apiError.response.status}`,
          'error'
        )
      }
    })
    handleClose()
  }

  function CopyText() {
    if (onCopy) onCopy()
    handleClose()
  }

  function onCopyIdClick() {
    navigator.clipboard.writeText(id)
    handleClose()
  }

  function removeMark() {
    removeMarkPost(id, type).then((data) => {
      if (!data.apiError) {
        if (onRemoveMark) onRemoveMark()
      } else {
        toggleSnackbar(
          `Ein Fehler im System ist aufgetreten: ${data.apiError.response.status}`,
          'error'
        )
      }
      handleClose()
    })
  }

  function bannAuthor({ reason }) {
    bannUser(userName, userEmail, reason).then(() => {
      if (onBann) onBann()
      handleClose()
    })
  }

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertRounded />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={handleClose}
          component="a"
          href={`mailto:${intl.formatMessage({
            id: 'menu.sentmail.mailadress',
          })}?${mailto}`}
        >
          Als E-Mail versenden
        </MenuItem>
        <Divider />
        {!!onCopy && <MenuItem onClick={CopyText}>Text kopieren</MenuItem>}
        {!!onCopy && <Divider />}
        {!!onMark && (
          <MenuItem
            onClick={() => dial.openDialog('mark', { onSubmit: setMark })}
          >
            Markieren
          </MenuItem>
        )}
        {!!mark && <Divider />}
        {!!mark && (
          <MenuItem onClick={() => removeMark()}>Markierung entfernen</MenuItem>
        )}
        {!isExpert && !!onDecline && <Divider />}
        {!isExpert && !!onDecline && (
          <MenuItem onClick={onDecline}>Nachträglich ablehnen</MenuItem>
        )}
        {!isExpert && !!onBann && <Divider />}
        {!isExpert && !!onBann && (
          <MenuItem
            onClick={() => dial.openDialog('bann', { onSubmit: bannAuthor })}
          >
            Autor sperren
          </MenuItem>
        )}
        {!!onDelete && <MenuItem onClick={onDelete}>Anfrage löschen</MenuItem>}
        {!!id && <MenuItem onClick={onCopyIdClick}>ID kopieren</MenuItem>}
      </Menu>
    </>
  )
}

ContextMenu.propTypes = {
  className: PropTypes.string,
  mailto: PropTypes.string,
  id: PropTypes.number,
  type: PropTypes.oneOf(['article', 'answer', 'comment']),
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  mark: PropTypes.bool,
  onMark: PropTypes.func,
  onRemoveMark: PropTypes.func,
  onCopy: PropTypes.func,
  onBann: PropTypes.func,
  onDelete: PropTypes.func,
  isExpert: PropTypes.bool,
  onDecline: PropTypes.func,
}

export default ContextMenu
