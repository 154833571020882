import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Trunc from 'truncate-html'
import parse, { domToReact } from 'html-react-parser'
import classNames from 'classnames'

import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
  },
  link: {
    color: theme.palette.red.main,
  },
  article: {
    ...theme.mixins.article,
  },
}))

function Copy({
  className,
  children,
  html,
  component,
  article,
  truncate,
  truncateextend,
}) {
  const classes = useStyles()
  const classnames = classNames(className, classes.root, {
    [classes.article]: article,
  })

  function TruncateOrNot(text) {
    if (truncate) {
      return Trunc(text, truncate, { byWords: true })
    } else {
      return text
    }
  }

  function renderCopy() {
    if (html) {
      const htmlWithBr = html.replace(/(?:\r\n|\r|\n)/g, '<br>')
      let containsBlockElements = false
      const renderedHTML = parse(TruncateOrNot(htmlWithBr), {
        replace: function (domNode) {
          if (domNode.type === 'tag') {
            if (domNode.name === 'a') {
              // ToDo: Style with Icon? -> maybe use button/textlink
              // if no special HTML needed for link styling, condition can be removed
              const href = domNode.attribs?.href
              const attrs = {
                target: '_blank',
                href: href.match(/^((http[s]?):)/)
                  ? href
                  : `${
                      href.match(/^(www\.)/)
                        ? `https://`
                        : 'https://www.deutschland-spricht-ueber-5g.de'
                    }${href}`,
              }
              return (
                <a className={classes.link} {...attrs}>
                  {domToReact(domNode.children)}
                </a>
              )
            } else if (domNode.name === 'br') {
              if (
                domNode.next?.type !== 'text' ||
                domNode.prev?.type !== 'text'
              ) {
                return <Fragment />
              }
            }
          }
        },
      })
      if (component === 'div' && !containsBlockElements) {
        return (
          <p>
            {renderedHTML} {truncateextend}
          </p>
        )
      } else {
        return (
          <>
            {renderedHTML} {truncateextend}
          </>
        )
      }
    } else {
      return TruncateOrNot(children)
    }
  }
  return (
    <Typography component={html ? 'div' : component} className={classnames}>
      {renderCopy()}
    </Typography>
  )
}

Copy.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  html: PropTypes.string,
  component: PropTypes.oneOf(['div', 'span', 'p']),
  article: PropTypes.bool,
  truncate: PropTypes.number,
  truncateextend: PropTypes.any,
}

Copy.defaultProps = {
  component: 'p',
}

export default Copy
